import React, { useState, useEffect, useRef } from 'react';
import { css } from 'linaria';
import axios from 'axios';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Main from '../components/main';
import { Action, Carousel } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import Gallery1Image from 'Img/gallery1.jpg';
import Gallery6Image from 'Img/gallery6.jpg';
import Gallery7Image from 'Img/gallery7.jpg';
// import AceImage from 'Img/ace.jpg';
// import VfwImage from 'Img/vfw.jpg';
// import AmericanLegionImage from 'Img/americanlegion.jpg';
import SealImage from 'Img/100_percent.png';

const styles = {
  nowrapCNC: css`
    white-space: nowrap;
  `,
  carousel: css`
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 helpers.$gutter;
    width: 100%;

    .pause {
      display: none;
    }

    .step {
      $size: 0.75em;

      appearance: none;
      background: #ccc;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      height: $size;
      margin: helpers.$gutter helpers.$gutter/2 0;
      overflow: hidden;
      padding: 0;
      text-indent: -100vw;
      width: $size;

      &.active {
        background-color: lighten(black, 20%);
      }
    }
  `,
  slide: css`
    display: block;
    width: 100%;

    * {
      position: relative;
    }

    img {
      width: 100%;
    }
  `,
  h1: css`
    display: block;
    clear: both;
  `,
  form: css`
    max-width: 80rem;
    margin: 0 auto 1em !important;
  `,
  seal: css`
    float: right;
    margin: 0 helpers.$gutter helpers.$gutter;
    max-width: 100%;
    width: 10em;
  `,
  locations: css`
    border: solid black;
    border-width: 2px 0;
    clear: both;
    display: grid;
    grid-gap: helpers.$gutter;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
    margin: helpers.$gutter;
    padding: helpers.$gutter 0;

    @include helpers.mq(home-locations) {
      grid-template-columns: auto auto auto;
      grid-template-rows: 22.5em;
    }

    div {
      position: relative;
      text-align: center;
    }

    img {
      max-width: 100%;

      @include helpers.mq(home-locations) {
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  `,
};

export default () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      axios.post(formRef.current.action, new FormData(formRef.current));
    });
  }, [formSubmitted]);

  return (
    <Layout>
      <SEO title="Home" />
      <Main>
        <Carousel className={styles.carousel} effect="fade" label="Carousel" prevnext={false} steps={true} auto={5000}>
          <div className={styles.slide}>
            <img alt="Family Pic" src={Gallery6Image} loading="lazy" />
          </div>
          <div className={styles.slide}>
            <img alt="Tent" src={Gallery1Image} loading="lazy" />
          </div>
          <div className={styles.slide}>
            <img alt="The Hut" src={Gallery7Image} loading="lazy" />
          </div>
        </Carousel>
        <h1 className={styles.h1}>
          Welcome to <span className={styles.nowrapCNC}>C-N-C</span> Fireworks!
        </h1>
        <p>
          C-N-C Fireworks is the only full time locally owned firework business located in Beatrice, Nebraska. Chris
          Cullison, founder and operator of C-N-C Fireworks, started in the firework industry in 2001. In 2008, after
          getting licensed by PGI (Pyrotechnics Guild International) and APA (American Pyro Association), C-N-C
          Fireworks was founded. Since then, this company has become a staple in the community. We make sure that we
          choose the highest grade of fireworks in order to bring you quality that outshines our competition.
        </p>
        <img className={styles.seal} alt="100% Satisfaction Guarantee" src={SealImage} />
        <p>
          We have three tent locations this year! Two locations will be in the Beatrice city limits and the other one is
          located in Lincoln. See the <Action href="/about">about us page</Action> for more information about each
          location.
        </p>
        <p>
          C-N-C Fireworks carries all your favorite brands as well as C-N-C Fireworks exclusive brand Hot Shot. All of
          our products are backed by our “100% No Dud Guarantee!”&dagger;
        </p>
        <p>
          <small>&dagger; defective products must be returned for a replacement</small>
        </p>

        <br />

        <div id="mc_embed_shell" hidden={formSubmitted}>
          <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
          <div id="mc_embed_signup">
            <form
              action="https://cncfireworks.us21.list-manage.com/subscribe/post?u=ca4c888b334ca331bbfbb58b6&amp;id=3fec2af2e2&amp;f_id=003bd4e6f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class={cls('validate', styles.form)}
              ref={formRef}
              target="_blank"
              onSubmit={evt => {
                evt.preventDefault();
                setFormSubmitted(true);
              }}
            >
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe for Special Pricing</h2>
                <div class="indicates-required">
                  <span class="asterisk">*</span> indicates required
                </div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL">
                    Email Address <span class="asterisk">*</span>
                  </label>
                  <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" />
                </div>
                <div class="mc-field-group">
                  <label for="mce-FNAME">First Name </label>
                  <input type="text" name="FNAME" class=" text" id="mce-FNAME" />
                </div>
                <div class="mc-field-group">
                  <label for="mce-LNAME">Last Name </label>
                  <input type="text" name="LNAME" class=" text" id="mce-LNAME" />
                </div>
                <div id="mce-responses" class="clear foot">
                  <div class="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div class="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                  <input type="text" name="b_ca4c888b334ca331bbfbb58b6_3fec2af2e2" tabindex="-1" value="" />
                </div>
                <div class="optionalParent">
                  <div class="clear foot">
                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div hidden={!formSubmitted}>
          <h2>Thanks for subscribing!</h2>
        </div>
      </Main>
    </Layout>
  );
};
